/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import logo from '../../assets/TTA_1024x1024.png';
import title from '../../assets/TTA_title_long.png';
import { faBars, faCaretDown, faFileSignature, faHome, faListCheck, faPeopleRoof, faPhone, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink, withRouter } from 'react-router-dom';
import NavigationBarLink, {DropdownLink} from '../NavigationBarLink/NavigationBarLink';
import './NavigationBar.scss'
import '../NavigationBarLink/NavigationBarLink.scss'
import { NavHashLink } from 'react-router-hash-link';

export default class NavigationBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }

    render() {
        return (
            <div onMouseLeave={()=>this.setState({show: false})} className='nav-bar'>
                <div style={{height: '100%', display: 'flex'}}>
                    <img src={logo} alt={'TTA LOGO'} style={{padding: 16, maxHeight: '100%'}}/>
                    <img className='desktop-nav' src={title} alt={'TTA TITLE'} style={{paddingTop: 25, paddingBottom: 20, maxHeight: '100%'}}/>
                </div>
                    <div className='mobile-nav'>
                        <Dropdown>
                            <Dropdown.Toggle style={{backgroundColor: 'transparent', border: 'none', color: 'var(--bs-primary)', marginBottom: 0}}>
                                <FontAwesomeIcon icon={faBars} size='2x'/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu bsPrefix='dropdown-menu dropdown-menu-nav'>
                                <div className='dropdown-column'>
                                    <DropdownLink hasActiveClass={true} title='Home' destination={'/'}/>
                                    <DropdownLink hasActiveClass={true} title='Services' destination={'/services'}/>
                                    <DropdownLink hasActiveClass={true} title='Pricing' destination={'/pricing'}/>
                                    <DropdownLink hasActiveClass={true} title='About Us' destination={'/about'}/>
                                </div>
                                <Dropdown.Divider/>
                                <NavLink to={'/oursites'}>
                                    <button className='custom-btn' style={{marginLeft: 19, width: '74%'}}>
                                        <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                                        Login
                                    </button>
                                </NavLink>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='desktop-nav' style={{gap: 32, paddingRight: 20, alignItems: 'center'}}>
                        <NavigationBarLink title='Home' icon={faHome} destination={'/'}/>  
                        <Dropdown >
                                <Link to='/services' className='service-toggle' onMouseEnter={()=>this.setState({show: true})} style={{display: 'flex', alignItems: 'center', gap: 4}}>
                                    Our Services
                                    <FontAwesomeIcon icon={faCaretDown}/>
                                </Link>
                            <Dropdown.Menu onMouseLeave={()=>this.setState({show: false})} show={this.state.show} rootCloseEvent='mouse-down' bsPrefix='dropdown-menu our-services-dropdown'>
                                <div className='dropdown-column'>
                                    <DropdownLink title='Contract Compliance' destination={'/services/#contract-compliance'}/>
                                    <DropdownLink title='Human Resource Management' destination={'/services/#hr'}/>
                                    <DropdownLink title='Payroll Processing and Tax Compliance' destination={'/services/#payroll'}/>
                                    <DropdownLink title={`Workers's Compensation and Unemployment`} destination={'/services/#bwc'}/>
                                    <DropdownLink title={`Strategic Accounting and Tax Services`} destination={'/services/#accounting'}/>
                                    <DropdownLink title={`Cutting-edge Software`} destination={'/services/#technology'}/>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <NavigationBarLink title='Pricing' icon={faPeopleRoof} destination={'/pricing'}/>
                        <NavigationBarLink title='About Us' icon={faPeopleRoof} destination={'/about'}/>
                        <NavLink className='custom-btn' to={'/oursites'}>
                            <FontAwesomeIcon icon={faRightFromBracket} style={{padding: '0px 6px'}}/>
                            Login
                        </NavLink>
                    </div>
            </div>
        );
    }
}

